<template>
  <div class="stats modal">
    <div v-if="found" class="modal-content">
      <!-- Date of Upload -->
      <div class="">
        <div class=" duration">
          <h5>From: {{ date1 }}</h5>
        </div>
        <div class=" duration">
          <h5>To: {{ date2 }}</h5>
        </div>

        <div v-if="review" class="text">
          <!-- Review from teacher -->
          <span style="font-weight: bold">Teacher Review : </span
          >{{ teacher_review }}
        </div>
        <div v-else class="text">
          Review Awaited.....
        </div>
        <div
          class="close-container"
          style="position: absolute;
                  top: 20px;
                  right: 20px;"
        >
          <span class="close" style="" @click="closeModal">&times;</span>
        </div>
        <br /><br />
      </div>

      <!-- Time table stats -->
      <div class="plots">
        <div class="img-div" v-for="(item, index) in imgList" :key="index">
          <img :src="item" class="img" />
        </div>
      </div>
    </div>
    <div v-else class="text">
      No time table uploaded yet
    </div>
  </div>

</template>

<script>
export default {
  name: "StatsTimeTable",
  props: ["found", "date1", "date2", "imgList", "review", "teacher_review"],
  created() {
    console.log(this.imgList);
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("hideModal", false);
      this.modelVisble = false;
    }
  },
  computed: {}
};
</script>

<style scoped>
.stats {
  width: 80%;
  margin: auto;
  /*border-style: inset;*/
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.text {
  margin-left: 50px;

  text-align: left;
  font-style: italic;
  color: black;
}

.plots {
  object-fit: contain;
  /* display: grid; */
  grid-template-columns: auto auto auto;
}

.plots img {
  max-width: 100%;
  margin: 10px;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  border: 3px red solid;
  background-color: #fefefe;
  margin: 1% auto; /* 15% from the top and centered */
  padding: 20px;
  border-spacing: 20px;

  border-collapse: separate;
  outline: 15px solid #fefefe;
  /*border: 1px solid #888;*/
  width: 80%; /* Could be more or less, depending on screen size */
}

.close {
  border: 2px black solid;

  padding: 4px;
  opacity: 0.4;
}

.close:hover {
  opacity: 1;
}

h5 {
  color: black;
}

.duration {
  text-align: left;
  margin-left: 50px;
  font-family: "Sriracha", cursive;
}

@media only screen and (max-width: 800px) {
  .modal-content {
    min-height: 90vh;
  }
  .plots {
    display: block;
  }
}
</style>
